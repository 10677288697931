$(document).ready(function(){
	
	// $( '.hero-carousel-init' ).slick(
	// 	{
	// 		dots: true,
	// 		arrows: true,
	// 		autoplay: true,
	// 		autoplaySpeed: 3000
	// 	}
	// );
	
	// $('.testimonial-grid-carousel').slick(
	// 	{
	// 		slidesToShow: 1,
	// 		arrows: true,
	// 		dots: true
	// 	}
	// 	);

}); //document ready end

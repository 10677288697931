$( document ).ready(function() {

  /////Slider on Card Grid release on mobile
	// var $card_grid = $('.card-grid-container');
	
	var standard_settings = {
	  dots: true,
	  arrows: true 
	}

	var center_variable_settings = { 
		dots: true,
		arrows: true,
		centerMode: true,
		slidesToShow: 1,
		variableWidth: true
	}

	var center_padding_settings = {
		dots: true,
		arrows: true,
		centerMode: true,
		centerPadding: '10px'
	}

///// Slick_on_mobile( $card_grid, standard_settings );
	slick_on_mobile( $( '.services-list-card-grid-container' ), center_variable_settings );
	slick_on_mobile( $( '.theme2 .affiliate-logos' ), standard_settings );
	slick_on_mobile( $( '.teams-card-grid-container' ), center_padding_settings );
	slick_on_mobile( $( '.leadership-card-grid-container' ), standard_settings );
	slick_on_mobile( $( '.promo-grid-container' ), center_variable_settings );

//Different Close target than other themes


}); ///End Document Ready


//Different modal close target than other themes
$('section.photo-gallery').on('click', '.photo-gallery-modal-close, .modal-background', function(e){
	e.stopImmediatePropagation();
	$('.lightbox-modal').removeClass('is-active');
	$("html").removeClass("is-clipped");
	$('.modal-content-slider').slick('unslick');
});